/* globals $ gtag submitGoogleAnalyticsConversion */

import { Duxstrap } from './duxfront/duxstrap/duxstrap-core';
import { DuxstrapLite } from './duxfront/duxstrap/duxstrap-lite';
import { DuxstrapPlus } from './duxfront/duxstrap/duxstrap-plus';
import { DuxstrapVideo } from './duxfront/duxstrap/duxstrap-video';

Duxstrap.init();
DuxstrapLite.init();
DuxstrapPlus.init();
DuxstrapVideo.init();

if (typeof global.contact_page === 'undefined') {
  global.contact_page = false;
}

// SUBMIT GOOGLE ANALYTICS CONVERSION
global.submitGoogleAnalyticsConversion = function () {
  console.log('conversion!');

  if (typeof gtag === 'undefined') return;

  gtag('event', 'click', {
    event_category: 'button',
    event_label: 'contact',
  });
};

// ON LOAD
$(() => {
  $('#search-modal').on('shown.bs.modal', () => {
    $('#search_query').focus();
  });
  $('.search-suggestion').click(function (e) {
    e.preventDefault();
    $('#search_query').val($(this).attr('href'));
    $('#search_query').closest('form').submit();
  });
  $('.submit-conversion').click(submitGoogleAnalyticsConversion);
});
